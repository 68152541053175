// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { getStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
import { Pushsnap, active, complete, activeAd, completedAd, snapAd,cloud, eye, onefingertap, play, profile } from '../assets';
import { openNotification } from "components/src/Notification.web";


export interface Props {
  navigation?: any;

}

interface CategoryDetails {
  pushcategory: string;
  value: number;
  percentage: number;
}

interface PushCategory {
    "total_impressions_details": CategoryDetails,
    "total_clicks_details": CategoryDetails,
    "total_shares_details": CategoryDetails,
    "total_placements_details": CategoryDetails,
    "total_plays_details": CategoryDetails,
    "total_views_details": CategoryDetails,
    "total_downloads_details":CategoryDetails,
    "total_follows_details": CategoryDetails
}

interface PushDataResponseType {
  "data": {        
    "total_ad_spend": number
      
} & PushCategory,
  "chart":[SnapshotChart],
  "error": "",
  "message": "error"
}

interface SnapshotChart {
  "date":string,
  "count":number,
  "month":string
  }

interface PushDataObj {
    "total_ad_spend": number,
    "total_impressions_details": CategoryDetails,
    "total_clicks_details": {
        "pushcategory": string,
        "value": number,
        "percentage": number
    },
    "total_shares_details": CategoryDetails,
    "total_placements_details": {
        "pushcategory":string,
        "value": number,
        "percentage": number
    },
    "total_plays_details": {
        "pushcategory": string,
        "value": number,
        "percentage": number
    },
    "total_views_details": {
        "pushcategory": string,
        "value": number,
        "percentage": number
    },
    "total_downloads_details": CategoryDetails,
    "total_follows_details": {
        "pushcategory": string,
        "value": number,
        "percentage": number
    }
}

interface CurrentImagesObj {
  "push": string,
  "active": string,
  "complete": string
}

export interface ActiveData {
  "id": string,
  "type": string,
  "attributes": {
    "id": 1,
    "account_id": number,
    "title":string,
    "track_id": number,
    "track_file": string,
    "video_file": string,
    "art_work": string,
    "status": string,
    "push_content_type": string,
    "placement_location": null,
    "content_type": string,
    "creative_type": [],
    "genres": [
      "Pop",
      "Trap",
      "Rap"
    ],
    "account_type": string,
    "cost_per_play": number,
    "cost_per_download": number,
    "cost_per_follow": number,
    "cost_per_impression": number,
    "cost_per_click": number,
    "cost_per_share": number,
    "cost_per_view": number,
    "total_budget": number,
    "campaign_duration": string,
    "ad_spend": number,
    "total_ad_spend": number,
    "total_actions_counts": {
      "impression": number,
      "click": number,
      "share": number,
      "play": number,
      "view": number,
      "download": number,
      "follow": number
    },
    "pushed_at": string,
    "resumed_at": null,
    "stopped_at": null,
    "completed_at": null,
    "created_at": string,
    "updated_at": string
  }

}

interface ActiveDataResponse {
  "data": [ActiveData],
  "message": "An error",
  "error": "ERROR"
}

type PushAudience = [{ Artists: number } ,
   { Songwriters: number } ,
   { Engineers: number } ,
    { Producers: number } ,
     { Musicians: number },
     { "Digital Labels": number },
    ]


interface PushStatsDataResponse{
  "data":PushStatsData,
  "message":"error",
  "error":"eee"
}

interface PushStatsData {
      "id": string,
      "type": string,
      "attributes": {
          "id": number,
          "account_id": number,
          "track_id": number,
          "track_file": string,
          "video_file": null,
          "art_work": string,
          "status": "active",
          "push_content_type": string,
          "placement_location": string,
          "content_type": string,
          "creative_type": [],
          "genres": [],
          "account_type": string,
          "cost_per_play": number,
          "cost_per_download": number,
          "cost_per_follow": number,
          "cost_per_impression": number,
          "cost_per_click": number,
          "cost_per_share": number,
          "cost_per_view": number,
          "total_budget": number,
          "campaign_duration": string,
          "ad_spend": number,
          "total_ad_spend": number,
          "track_type":string,
          "total_actions_counts": {
              "impression": number,
              "click": number,
              "share": number,
              "play": number,
              "view": number,
              "download": number,
              "follow": number
          },
          "pushed_at": string,
          "resumed_at": string,
          "stopped_at": string,
          "completed_at": string,
          "created_at": string,
          "updated_at": string,
          "push_chart":[{
              date: string,
              count:number,
              day: string,
              day_of_month:number
          }]
          "push_audience": { [key: string]: number }[] 
      }
}

interface S {
  allcreativetypes: any[],
  pushplacementStats: PushAudience
  chartdata: any
  isMobile: boolean
  activestepMobile: string,
  selectedTab: string,
  activechartdata: any
  isAudioLoading: boolean,
  token: string;
  activeAdCampaign: ActiveData[],
  isSnapLoading: boolean,
  pushDataList: PushDataObj,
  currentImages: CurrentImagesObj,
  selectedAdCampaign: string;
  selectPushStatIndex:string;
  PushStatsData:PushStatsData;
  isPushStats:boolean;
  statsPush:object;
  isPushStatsLoading:boolean;
}

interface SS {
  id: any;
}

export default class PushPlayerAdResultsController extends BlockComponent<Props, S, SS> {
  getActiveTracksCallId: string = "";
  getAdCampaignDatCallId: string = "";
  getPushSnapshotCallId: string = "";
  getStatStopAndPushCallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      isMobile: window.innerWidth < 600,
      activestepMobile: "1",
      selectedTab: "Audio",
      currentImages: {
        push: Pushsnap,
        active: active,
        complete: complete,
      },
      allcreativetypes: [
        {
          pushcategory: "Impressions",
          pushtype: "From Display Ads",
          value: 0,
          percentage: "0%", image: eye
        },
        { pushcategory: "Clicks + Taps", pushtype: "From Display Ads", value: 0, percentage: "0%", image: onefingertap },
        { pushcategory: "Placements", pushtype: "From Player Ads", value: 0, percentage: "0%", image: eye },
        { pushcategory: "Plays", pushtype: "From Player Ads", value: 0, percentage: "0%", image: play },
        { pushcategory: "Views", pushtype: "From Player Ads", value: 0, percentage: "0%", image: play },
        { pushcategory: "Downloads", pushtype: "From Player Ads", value: 0, percentage: "0%", image: cloud },
        { pushcategory: "Follows", pushtype: "From Player Ads", value: 0, percentage: "0%", image: profile },
      ],
      pushplacementStats:[
        {
          "Artists": 0
        },
        {
          "Songwriters": 0
        },
        {
          "Engineers": 0
        },
        {
          "Producers": 0
        },
        {
          "Musicians": 0
        },
        {"Digital Labels":0}
      ],
      chartdata: [
        {
          date:'01-01-2000',
          count: 0,
          month: 'Jan'

        },
        {
          date:'01-01-2000',
          count: 0,
          month: 'Feb'
        },
        {
          date:'01-01-2000',
          count: 0,
          month: 'Mar'
        },
        {
          date:'01-01-2000',
          count: 0,
          month: 'Apr'
        },
        {
          date:'01-01-2000',
          count: 0,
          month: 'May'
        },
        {
          date:'01-01-2000',
          count: 0,
          month: 'June'
        },
      ],
      activechartdata: [
        {
          date: "2024-01-11",
          count:1,
          day: "Sun",
          day_of_month:11
        },
        {
          date: "2024-01-11",
          count:1,
          day: "Sun",
          day_of_month:11
        },
        {
          date: "2024-01-11",
          count:1,
          day: "Sun",
          day_of_month:11
        },
        {
          date: "2024-01-11",
          count:1,
          day: "Sun",
          day_of_month:11
        },
        {
          date: "2024-01-11",
          count:1,
          day: "Sun",
          day_of_month:11
        },
        {
          date: "2024-01-11",
          count:1,
          day: "Sun",
          day_of_month:11
        }
      ],
      isAudioLoading: false,
      token: "",
      activeAdCampaign: [],
      isSnapLoading: false,
      pushDataList: {
        "total_ad_spend": 0,
        "total_impressions_details": {
          "pushcategory": "Impressions",
          "value": 0,
          "percentage": 0
      },
      "total_clicks_details": {
          "pushcategory": "Clicks",
          "value": 0,
          "percentage": 0
      },
      "total_shares_details": {
          "pushcategory": "Shares",
          "value": 1,
          "percentage": -100.0
      },
      "total_placements_details": {
          "pushcategory": "Placement",
          "value": 2,
          "percentage": 100
      },
      "total_plays_details": {
          "pushcategory": "Plays",
          "value": 2,
          "percentage": 100
      },
      "total_views_details": {
          "pushcategory": "Views",
          "value": 0,
          "percentage": 0
      },
      "total_downloads_details": {
          "pushcategory": "Downloads",
          "value": 0,
          "percentage": 0
      },
      "total_follows_details": {
          "pushcategory": "Follows",
          "value": 0,
          "percentage": 0
      }
      },
      selectedAdCampaign: "active",
      selectPushStatIndex:"",
      PushStatsData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          account_id: 0,
          track_id: 0,
          track_file: "",
          video_file: null,
          art_work: "",
          status: "active",
          push_content_type: "",
          placement_location: "",
          content_type: "",
          creative_type: [],
          genres: [],
          account_type: "",
          cost_per_play: 0,
          cost_per_download: 0,
          cost_per_follow: 0,
          cost_per_impression: 0,
          cost_per_click: 0,
          cost_per_share: 0,
          cost_per_view: 0,
          total_budget: 0,
          campaign_duration: "",
          ad_spend: 0,
          total_ad_spend: 0,
          total_actions_counts: {
            impression: 0,
            click: 0,
            share: 0,
            play: 0,
            view: 0,
            download: 0,
            follow: 0
          },
          pushed_at: "",
          resumed_at: "",
          stopped_at: "",
          completed_at: "",
          created_at: "",
          updated_at: "",
          push_audience: [
            {
              "Artists": 0
            },
            {
              "Songwriters": 0
            },
            {
              "Engineers": 0
            },
            {
              "Producers": 0
            },
            {
              "Musicians": 0
            },
            {"Digital Labels":0}
          ],
          track_type: "",
          push_chart: [
            {
              date: "2024-01-11",
              count:1,
              day: "Sun",
              day_of_month:11
            }
          ]
        }
      },
      isPushStats:false,
      isPushStatsLoading:false,
      statsPush:{}
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);
    let token = await getStorageData("token");
    this.setState({ token: token });
    this._getActiveCompleteData(this.state.selectedAdCampaign, 'Audio')
    this._pushSnapshot()
  }
  componentDidUpdate(prevProps: {}, prevState: S) {
    if (prevState.isMobile !== this.state.isMobile) {
      this.handleResize();
    }
  }

  _pushSnapshot = () => {
    this.setState({ isSnapLoading: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPushSnapshotCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_promotecontent/ad_campaigns/push_snapshot`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  _getActiveCompleteData = (selectedCampaign: string, upload_type: string) => {
    this.setState({ isAudioLoading: true })
    let currentUrl = ""
    if (selectedCampaign === "active") {
      currentUrl = "active_ad_campaigns"
    } else {
      currentUrl = "completed_ad_campaigns"
    }
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActiveTracksCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_promotecontent/ad_campaigns/${currentUrl}?upload_type=${upload_type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  statsStopAndPushData = (selectedbtn: string) => {
    this.setState({ isPushStatsLoading: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStatStopAndPushCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_promotecontent/ad_campaigns/${selectedbtn}?id=${this.state.PushStatsData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPushStatsData = (campaign_id: string) => {
    this.setState({ isPushStatsLoading: true ,selectPushStatIndex:campaign_id})
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdCampaignDatCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_promotecontent/ad_campaigns/${campaign_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 600 });

  };

  selectPushStep = (step: string) => {
    if (this.state.isMobile) {
      this.setState({ activestepMobile: step })
    }

  }
  onBack = () => {
    this.setState({ activestepMobile: "1" })
  }
  selectTab = (tab: string) => {
    this.setState({ selectedTab: tab })
    this._getActiveCompleteData(this.state.selectedAdCampaign, tab)
  }



 getPctTxt = (data: any): {sign:string,value:string} => {
   if (data === 0 || data === "0") {
    return { sign: "", value: "0" }; // Handle 0 explicitly
}
    if (data) {
        if (data.startsWith("-")) {
            return {sign:"-",value:data.replace("-", "")+"%"}
        }else{
        return {sign:"+",value:data}
        }
    } else {
        return {sign:"",value:"0"}
    }
}
  handlePushActiveComplete = (id: string, iconType: string) => {
    this.selectPushStep(id)
    this.handleIconClick(iconType)
  }
  handleIconClick = (imageType: string) => {
    switch (imageType) {
      case 'active':
        this.setState({
          currentImages: {
            push: snapAd,
            active: activeAd,
            complete: complete,
          },
          selectedAdCampaign: "active",
          selectedTab: "Audio"
        });
        this._getActiveCompleteData("active", "Audio")
        break;
      case 'complete':
        this.setState({
          currentImages: {
            push: snapAd,
            active: active,
            complete: completedAd,
          },
          selectedAdCampaign: "complete",
          selectedTab: "Audio"
        });
        this._getActiveCompleteData('complete', "Audio")
        break;
      default:
        this.setState({
          currentImages: {
            push: Pushsnap,
            active: active,
            complete: complete,
          },
          selectedAdCampaign: "active",
          selectedTab: "Audio"
        });
        this._getActiveCompleteData(this.state.selectedAdCampaign, "Audio")

    }
  };

  handleActiveAdTracksData = (responseJson: ActiveDataResponse) => {
    if (responseJson !== undefined && !responseJson.message && !responseJson.error) {
      if (responseJson.data.length > 0) {
        this.setState({ activeAdCampaign: responseJson.data, isAudioLoading: false })
      } else {
        this.setState({ activeAdCampaign: [], isAudioLoading: false })
      }
    } else {
      this.setState({ activeAdCampaign: [], isAudioLoading: false })
    }
  }



  handleGetPushSnapshot = (responseJson: PushDataResponseType) => {
    const mapping: Record<string, keyof PushCategory> = {
      "Impressions": "total_impressions_details",
      "Clicks + Taps": "total_clicks_details",
      "Placements": "total_placements_details",
      "Plays": "total_plays_details",
      "Views": "total_views_details",
      "Downloads": "total_downloads_details",
      "Follows": "total_follows_details"
    };
    if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
      const updatedCreativeTypes = this.state.allcreativetypes.map((item) => {
        const mapping_key = mapping[item.pushcategory];
        return {
          ...item,
          value: responseJson.data[mapping_key].value,
          percentage:responseJson.data[mapping_key].percentage
        };
      });

const updatedNewChart= this.state.chartdata.map((item:SnapshotChart,index:any)=>{
  return{
    date:responseJson.chart[index].date,
    month:responseJson.chart[index].month,
    count:responseJson.chart[index].count,
  }})

      this.setState({
        pushDataList: responseJson.data,
        allcreativetypes: updatedCreativeTypes,
        chartdata:updatedNewChart,
        isSnapLoading: false
      });
    } else {
      this.setState({
        isSnapLoading: false
      })
    }
  }

  handlePlayerAdView=(responseJson:PushStatsDataResponse)=>{
    if(responseJson.data.attributes.track_type=="Track"){
      return{
        Plays: responseJson.data.attributes.total_actions_counts.play,
        CPP: responseJson.data.attributes.cost_per_play,
        Downloads:responseJson.data.attributes.total_actions_counts.download,
        CPD:responseJson.data.attributes.cost_per_download,
        Follows:responseJson.data.attributes.total_actions_counts.follow,
        CPF:responseJson.data.attributes.cost_per_follow

      }
}else if (responseJson.data.attributes.track_type=="Song"){
    return{
      Plays: responseJson.data.attributes.total_actions_counts.play,
      CPP: responseJson.data.attributes.cost_per_play,
      Follows:responseJson.data.attributes.total_actions_counts.follow,
      CPF:responseJson.data.attributes.cost_per_follow,
      Shares:responseJson.data.attributes.total_actions_counts.share,
      CPS:responseJson.data.attributes.cost_per_share

    }
}else{
    return{
      Views: responseJson.data.attributes.total_actions_counts.view,
      CPV: responseJson.data.attributes.cost_per_view,
      Follows:responseJson.data.attributes.total_actions_counts.follow,
      CPF:responseJson.data.attributes.cost_per_follow,
      Shares:responseJson.data.attributes.total_actions_counts.share,
      CPS:responseJson.data.attributes.cost_per_share

    }
}
  }
  handlePushStatsData=(responseJson:PushStatsDataResponse)=>{
    let newStats={};
    if (responseJson !== undefined && !responseJson.message && !responseJson.error) {
     if(responseJson.data.attributes.push_content_type =="player_ad"){
      newStats = this.handlePlayerAdView(responseJson)
    }else{
      if(responseJson.data.attributes.track_type=="Track"||responseJson.data.attributes.track_type=="Song"){
        newStats={
          Plays: responseJson.data.attributes.total_actions_counts.play,
          CPP: responseJson.data.attributes.cost_per_play,
          Impressions:responseJson.data.attributes.total_actions_counts.impression,
          CPI:responseJson.data.attributes.cost_per_impression,
          Clicks:responseJson.data.attributes.total_actions_counts.click,
          CPC:responseJson.data.attributes.cost_per_click

        }
  }else{
      newStats={
        Views: responseJson.data.attributes.total_actions_counts.view,
        CPV: responseJson.data.attributes.cost_per_view,
        Impressions:responseJson.data.attributes.total_actions_counts.impression,
        CPI:responseJson.data.attributes.cost_per_impression,
        Clicks:responseJson.data.attributes.total_actions_counts.click,
        CPC:responseJson.data.attributes.cost_per_click

      }
  }
    } 
      const updatedPlacementStatsValue= this.state.pushplacementStats.map((item)=>{
        const key = Object.keys(item)[0]
        const updatedValue:any= responseJson.data.attributes.push_audience.find((audience)=>key in audience)
        return updatedValue ? { [key]: updatedValue[key] } : item;
      }) as PushAudience
      const updatedPushImpression= this.state.activechartdata.map((item:SnapshotChart,index:any)=>{
        return{
          date:responseJson.data.attributes.push_chart[index].date,
          day:responseJson.data.attributes.push_chart[index].day,
          count:responseJson.data.attributes.push_chart[index].count,
          day_of_month:responseJson.data.attributes.push_chart[index].day_of_month,
      
        }})
      this.setState({statsPush:newStats,
      pushplacementStats:updatedPlacementStatsValue,
      PushStatsData: responseJson.data, 
      activechartdata:updatedPushImpression,
      isPushStatsLoading: false
      })
    } else {
      openNotification(responseJson.message,"Success")
      this.setState({ PushStatsData: this.state.PushStatsData, isPushStatsLoading: false })
    
    }
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (apiRequestCallId === this.getActiveTracksCallId) {
        this.handleActiveAdTracksData(responseJson);

      } else if (apiRequestCallId === this.getAdCampaignDatCallId) {
        this.handlePushStatsData(responseJson)

      }
      else if (apiRequestCallId === this.getPushSnapshotCallId) {
        this.handleGetPushSnapshot(responseJson)
      }else if (apiRequestCallId=== this.getStatStopAndPushCallId){
        this.handlePushStatsData(responseJson)
        this._getActiveCompleteData(this.state.selectedAdCampaign, this.state.selectedTab)
      }
    }
    // Customizable Area End
  }
}

// Customizable Area End